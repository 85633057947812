/* ReportsPage.css */

.data-container {
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 20px;
  background-color: #f0f0f0;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 24px;
}

.data-summary {
  margin-top: 20px;
}

.data-summary p {
  font-size: 16px;
  margin: 5px 0;
}

h2 {
  font-size: 20px;
  margin-top: 20px;
}

.flow-list {
  display: grid;
  grid-template-columns: repeat(5, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.flow-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.flow-item p {
  font-size: 16px;
  margin: 5px 0;
}
.font-size-12 {
  font-size: 12px;
}

.badge-soft-dark {
  background-color: #333; /* Replace with your preferred dark color */
  color: #fff; /* Replace with your preferred text color */
  padding: 4px 8px; /* Adjust padding as needed */
  border-radius: 4px; /* Adjust border radius as needed */
}

.badge.bg-secondary {
  background-color: #6c757d; /* Replace with your preferred secondary color */
  color: #fff; /* Replace with your preferred text color */
}

.flow-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Style for each <li> item within the <ul> */
.flow-list li {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Style for the <p> elements within each <li> item */
.flow-list li p {
  font-size: 16px;
  margin: 5px 0;
}

/* Style for <strong> elements within <p> elements */
.flow-list li p strong {
  font-weight: bold;
  color: #333; /* Adjust the color as needed */
}

/* Style for the "Yes" and "No" text */
.flow-list li p strong.yes {
  color: #0a9d58; /* Green color for "Yes" */
}

.flow-list li p strong.no {
  color: #ff6347; /* Red color for "No" */
}

.data-container h1 {
  font-size: 24px;
  color: #333; /* Adjust the color as needed */
  margin-bottom: 20px;
}

/* Style for the data summary container */
.data-summary {
  background-color: #f5f5f5; /* Light gray background color */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style for each data summary paragraph */
.data-summary p {
  margin: 10px 0;
  font-size: 16px;
}

/* Style for the "Flow Comparison List" heading */
.data-container h2 {
  font-size: 20px;
  color: #333; /* Adjust the color as needed */
  margin-top: 20px;
}

/* Your CSS file */

/* Style for the "Replay Report" heading */
.data-container h1 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
  text-align: center; /* Center align the heading */
}

/* Style for the data summary container */
.data-summary {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px; /* Increase border radius for a rounded appearance */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slightly more pronounced box shadow */
}

/* Style for each data summary paragraph */
.data-summary p {
  margin: 10px 0;
  font-size: 18px; /* Slightly larger font size */
  color: #555; /* Darker text color */
}

/* Style for the "Flow Comparison List" heading */
.data-container h2 {
  font-size: 24px; /* Reduce font size for subheading */
  color: #333;
  margin-top: 30px; /* More spacing at the top */
  text-align: center; /* Center align the subheading */
}

/* Style for the individual items in the flow comparison list */
.flow-item {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style for the "Yes" and "No" text */
.flow-item strong.yes {
  color: #0a9d58; /* Green color for "Yes" */
}

.flow-item strong.no {
  color: #ff6347; /* Red color for "No" */
}

/* Center align the content in the flow comparison items */
.flow-item p {
  text-align: center;
}

/* Style for the "Node Comparison" heading */
.flow-item h3 {
  font-size: 20px; /* Increase font size for sub-subheading */
  margin-top: 15px; /* Additional spacing at the top */
}
.collapsible-header {
  cursor: pointer;
}

.have-difference {
  font-weight: bold;
}

/* Style for "Yes" */
.have-difference.yes {
  color: #ff6347; /* Red color for "Yes" */
}

/* Style for "No" */
.have-difference.no {
  color: #0a9d58; /* Green color for "No" */
}

/* Style for the collapsible header */
.collapsible-header {
  /* Your existing styles */
  transition: background-color 1.2s ease; /* Add transition for background color */
}

/* Style for the open header */
.collapsible-header.open {
  background-color: #e0e0e0;
}

/* Style for the collapsible content */
.collapsible-content {
  max-height: 0; /* Start with a height of 0 */
  overflow: hidden; /* Hide the content that exceeds max-height */
  transition: max-height 1.2s ease; /* Add transition for max-height */
}

/* Style for the open content */
.collapsible-content.open {
  max-height: 500px; /* Set a maximum height when open (adjust as needed) */
}
.node-comparison-strong {
  margin-right: 10px;
}
.missing-keys {
  max-height: 150px; /* Adjust the height as needed */
  overflow-y: auto; /* Add a vertical scrollbar when content overflows */
  border: 1px solid #ccc; /* Add a border for clarity */
}

/* Style the list items inside the scrollbar container */
.missing-keys ul {
  list-style-type: none;
  padding: 0;
}

.missing-keys li {
  margin: 10px 0;
  padding: 5px;
  border: 1px solid #eee;
  background-color: #f9f9f9;
}