.header_raw-Uri {
  text-align: left;
  padding-left: 15px;
  max-width: 6%;
  width: 6%;
  font-weight: 600;
  text-transform: capitalize;
}
.header_flowname {
  font-weight: 600;
  text-transform: capitalize;
  text-align: left;
  max-width: 170px;
  padding-left: 10px;
  min-width: 170px;
  width: 170px;
  white-space: nowrap;
}
.header_nodeID {
  text-align: left;
  max-width: 400px;
  min-width: 325px;
  width: 325px;
  font-weight: 600;
  white-space: nowrap;
  text-transform: capitalize;
}
.header_key {
  text-align: left;
  max-width: 140px;
  min-width: 140px;
  width: 140px;
  font-weight: 600;
  white-space: nowrap;
  text-transform: capitalize;
}
.header_expected {
  text-align: left;
  max-width: 400px;
  min-width: 100px;
  width: 400px;
  font-weight: 600;
  white-space: nowrap;
  text-transform: capitalize;
}
.header_current {
  text-align: left;
  font-weight: 600;
  text-transform: capitalize;
}
.header_Comparison-Type {
  text-align: left;
  white-space: nowrap;
  max-width: 150px;
  min-width: 150px;
  width: 150px;
  font-weight: 600;
  text-transform: capitalize;
}
.header_Location {
  text-align: left;
  white-space: nowrap;
  max-width: 150px;
  min-width: 150px;
  width: 150px;
  font-weight: 600;
  text-transform: capitalize;
}

.table-border {
  border: 1px solid #ccc;
}

.table_rawuri {
  display: flex;
  color: rgb(107 114 128);
  font-size: 14px;
}
.table_rawuributton {
  height: fit-content;
}
.table_rawurip {
  padding-left: 7px;
  white-space: nowrap;
}
.flowname {
  display: flex;
  align-items: baseline;
  white-space: nowrap;
}

.flownamediv {
  display: flex;
}
.flownametd {
  color: rgb(107 114 128);
  font-size: 14px;
  padding-left: 8px;
}
.node_div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 55px;
}
.balancediv {
  margin-left: 15px;
}
.balancediv_row1td1 {
  margin-left: 15px;
  color: rgb(107 114 128);
  font-size: 14px;
  min-width: 140px;
}
.balancediv_row2 {
  padding-bottom: 5px;
  padding-top: 5px;
}
.balancediv_row1tdexpexted {
  margin-left: 35px;
  text-align: left;
  color: rgb(107 114 128);
  font-size: 14px;
  width: 405px;
  max-width: 405px;
  min-width: 405px;
}

.balancediv_row1tdcurrent {
  margin-left: 15px;
  color: rgb(107 114 128);
  font-size: 14px;
}
